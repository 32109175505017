
<template>
    <v-card elevation="1">
        <div class="slide-out-section pa-0">


            <div class="form-section bg-white pa-0">
                <v-col class="ma-0 pt-3 pb-4 pl-0 pr-0">
                    <h2 class="font-weight-medium pl-5 pb-3">Insurance</h2>
                    <v-divider></v-divider>
                </v-col>

                <div class="px-6 pb-6">
                    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                    <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
                    <v-row class="mt-1 mb-6 px-4">
                        <v-col cols="9" class="ma-0 pa-0 hb-text-light">
                        </v-col>
                        <v-col cols="3" class="ma-0 pa-0 text-right">
                            <a class="hb-link" @click="showAddInsurance = true">+ Add Insurance Option</a>
                        </v-col>
                    </v-row>
                    <v-card elevation="1">
                        <v-data-table
                            :headers="headers"
                            :items="services"
                            disable-pagination
                            hide-default-footer
                            class="hb-data-table"
                        >
                            <template v-slot:item.name="{ item }">{{item.Insurance.name}}</template>
                            <template v-slot:item.premium="{ item }">
                                <span v-show="item.Insurance.premium_type == '$'">{{item.Insurance.premium_type}}</span>
                                {{item.Insurance.premium_value}}
                                <span v-show="item.Insurance.premium_type == '%'">{{item.Insurance.premium_type}}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <hb-menu
                                    options
                                    align-right
                                >
                                    <v-list>
                                        <!--
                                        <v-list-item @click="editItem(item)">
                                            <v-list-item-title>Edit</v-list-item-title>
                                        </v-list-item>
                                        -->
                                        <v-list-item @click.stop="removeService(item)">
                                            <v-list-item-title>Delete</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </hb-menu>
                            </template>
                        </v-data-table>
                    </v-card>
                </div>

                <add-insurance-dialog v-model="showAddInsurance" v-if="showAddInsurance" :template_id="template_id" @fetch="fetchData" @showSuccess="showSuccessMsg"></add-insurance-dialog>

                <!--
                <add-insurance-dialog v-if="showEditInsurance" :selected="selected" :template_id="template_id" @close="closeDialog" @fetch="fetchData" @showSuccess="showSuccessMsg"></add-insurance-dialog>
                -->

                <!-- <div class="table" v-show="services.length">
                    <div class="table-row">
                        <div class="table-head" style="flex-basis: 50%;">
                            Name
                        </div>
                        <div class="table-head">
                            Premium
                        </div>
                        <div class="table-head actions">
                            &nbsp;
                        </div>
                    </div>

                    <div class="table-row" v-for="service in services" @click="editItem(service.Insurance)">

                        <div class="table-cell" style="flex-basis: 50%;">
                            <strong>{{service.Insurance.name}}</strong><br />
                            {{service.Insurance.description}}
                        </div>

                        <div class="table-cell">
                            <span v-show="service.Insurance.premium_type == '$'">{{service.Insurance.premium_type}}</span>
                            {{service.Insurance.premium_value}}
                            <span v-show="service.Insurance.premium_type == '%'">{{service.Insurance.premium_type}}</span>
                        </div>
                        <div class="table-cell actions">
                            <span @click.stop="removeService(service)" class="icon delete"></span>
                        </div>

                    </div>
                </div> -->
            </div>
        </div>

        <v-dialog v-model="showAdd" persistent max-width="800">
            <v-card>
                <v-card-title>
                    <v-row class="ma-0">
                        <v-col cols="10 pa-0" >Edit Insurance</v-col>
                        <v-col cols="2" class="pa-0 text-right">
                            <v-icon @click="closeWindow()">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <edit-insurance v-if="destroyComponent" size="lg" slot="body" :selected="selected" @showSuccess="showSuccessStatus" @close="closeWindow" @refetch="fetchData"></edit-insurance>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-dialog>

        <!-- <modal v-if="showEdit" size="lg" allow-click-away="false" @close="closeWindow">
            <h3 slot="header">Edit Insurance</h3>
            <edit-insurance size="lg" slot="body" :selected="selected" @close="closeWindow" @refetch="fetchData">
            </edit-insurance>
        </modal> -->

    </v-card>
</template>


<script type="text/babel">
    import Dropdown from "../../assets/Dropdown.vue";
    import Status from "../../includes/Messages.vue";
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import EditInsurance from '../EditInsurance.vue';
    import Modal from '../../assets/Modal.vue';
    import AutoComplete from '../../assets/Autocomplete.vue';
    import { EventBus } from '../../../EventBus.js';
    import AddInsurance from './AddInsuranceDialog.vue'


    export default{
        name: "LeaseTemplateInsurance",
        data(){
            return {
                insurance: {
                    id: '',
                    name: '',
                },
                selected: {},
                showAddService:false,
                services:[],
                showEdit: false,
                insurance_products: [],
                hasSubmitted: false,
                showAddInsurance : false,
                showEditInsurance : false,
                destroyComponent : true,
                showAdd: false,
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Premium", value: "premium" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],

            }
        },
        components:{
            Dropdown,
            Status,
            Loader,
            Modal,
            AutoComplete,
            EditInsurance,
            'add-insurance-dialog' : AddInsurance
        },
        created(){
            this.fetchData();
            EventBus.$on('undo_114', this.fetchData);
        },
        destroyed(){
            EventBus.$off('undo_114', this.fetchData);
        },
        methods:{
            closeWindow(){
                this.showEdit = false;
                this.showAdd = false;
                this.selected = {};
                setTimeout(() => {
                    this.destroyComponent = false;
                }, 200);
            },
            editItem(c){
                this.destroyComponent = true;
                this.selected = c;
                this.showEditInsurance = true;
            },

            fetchData(){
                api.get(this, api.TEMPLATES + this.template_id + '/services/insurance').then(r => {
                    this.services = r.services;
                });
            },
            removeService(service){
                api.delete(this, api.TEMPLATES + this.template_id + '/services/', service.id).then(r => {
                    this.fetchData();
                });
            },
            closeDialog(){
                this.showAddInsurance = false;
            },
            showSuccessMsg(name){
                this.successSet(this.$options.name, 'Insurance Option "' + name + '" has been added to this lease template');
            },
            showSuccessStatus(name){
                this.successSet(this.$options.name, 'Insurance Option has been updated');
            }
        },
        props: ['template_id'],
        watch:{
            template_id(val){
                this.clearForm();
                if(val){
                    this.fetchData();
                }
            },
            insurance(){
                if(this.insurance != undefined) {
                    this.hasSubmitted = false;
                }
            }
        }
    }
</script>


<style scoped>
    .grey-border{
        border-bottom : 1px solid #DFE3E8;;
    }
    .lease-heading{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
        .mrl--25px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .key-label{
      background: #F4F6F8;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
    .add-role-btn{
        font-size: 15px;
        text-decoration: none;
    }
    .add-role-btn:focus{
        border: none;
    }
    .insurance-lease-table {
        background: #FFFFFF;
        box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
        border-radius: 4px;
    }
    .insurance-lease-table .table-head{
        background: #FFFFFF;
        /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
        border-bottom: 1px solid rgba(0, 0, 0, 0.22);
        color: #474F5A;
        font-size: 16px;
        font-weight: 500;
    }
    /* .insurance-lease-table .table-row:hover:not(:first-child){
        background: #F9FAFB;
    } */
    .insurance-lease-table .table-row {
        cursor: inherit;
    }
    .insurance-lease-table .table-row .table-cell{
        border-bottom: 1px solid #dce8ef;
    }
    .role-modal-header{
        font-size: 16px;
        color: #101318;
    }
    .no-border-all{
        border: none;
    }
    .light-text{
        color: #637381;
    }
    .key-heading{
        font-size: 15px;
        color: #474F5A;
    }
    .bg-white{
        background-color: #FFFFFF;
    }
</style>
