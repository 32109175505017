import { render, staticRenderFns } from "./AddInsuranceDialog.vue?vue&type=template&id=725d150f&scoped=true&"
import script from "./AddInsuranceDialog.vue?vue&type=script&lang=js&"
export * from "./AddInsuranceDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddInsuranceDialog.vue?vue&type=style&index=0&id=725d150f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725d150f",
  null
  
)

export default component.exports