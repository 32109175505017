<template>
    <v-card elevation="1">

        <status class="pa-4 mt-0" @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status class="pa-4 mt-0" @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

        <v-col class="pl-0 pr-0 pt-4 pb-4 bg-white grey-border mt-0">
            <div class="lease-heading pl-6">Configure Lease Information</div>
        </v-col>

        <div class="slide-out-tenant-header bg-white grey-border pt-2 pb-3">
            <div class="slide-out-info ml-0">
                <v-row>
                    <v-col cols="9">
                        <p class="mb-0">You can edit this lease’s information here.</p>
                    </v-col>
                    <v-col cols="3" v-if="isEditable" class="d-flex align-center justify-end py-0 my-0 pt-1">
                        <a class="add-role-btn mr-2"  @click="isEditable = false">Cancel</a>
                        <hb-btn :disabled="isLoading($options.name)" @click="save" color="primary">Save</hb-btn>
                        <span v-show="isLoading($options.name)" >
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                        </span>
                    </v-col>
                    <v-col cols="3" v-else class="text-right">
                        <a class="add-role-btn" @click="isEditable = true">Edit</a>
                    </v-col>
                </v-row>
            </div>
        </div>

        <div class="slide-out-section pt-0 bg-white pb-0 mb-0" :class="{'no-clickable' : !isEditable }">
            <div class="form-section large pa-0 ma-0">

                <v-row class="mrl--25px" v-if="template.name || isEditable">
                    <v-col md="4" class="key-label pl-6">
                        <div class="pt-1 font-15px">Name</div>
                    </v-col>
                    <v-col md="8 pl-5 pr-7">
                        <div class="form-input-container lease-detail pt-1" v-if="!isEditable">
                            {{template.name}}
                        </div>
                        <div class="form-input-container" v-else :class="{'has-error': errors.has('name') }">
                            <v-text-field
                                dense
                                solo
                                flat
                                hide-details
                                v-model="template.name"
                                v-validate="'required|max:45'"
                                id="name"
                                name="name"
                                placeholder="Enter Name">
                            </v-text-field>
                            <span v-show="errors.has('name')" class="status-block error-block field-error pl-3">{{ errors.first('name') }}</span>
                        </div>
                    </v-col>
                </v-row>

                <v-row class="top-border mrl--25px" v-if="template.description || isEditable">
                    <v-col md="4" class="key-label pl-6">
                        <div class="pt-1 font-15px">Description</div>
                    </v-col>
                    <v-col md="8 pl-5 pr-7">
                        <div class="form-input-container lease-detail  pt-1" v-if="!isEditable">
                            {{template.description}}
                        </div>
                        <div class="form-input-container" v-else :class="{'has-error': errors.has('description') }">
                            <v-textarea
                                dense
                                solo
                                flat
                                rows="2"
                                hide-details
                                name="description"
                                id="description"
                                v-model="template.description"
                                v-validate="'max:1000'"
                                placeholder="Enter Description">
                            </v-textarea>
                            <span v-show="errors.has('description')" class="status-block error-block field-error pl-3">{{ errors.first('description') }}</span>
                        </div>
                    </v-col>
                </v-row>

                <v-row class="top-border mrl--25px">
                    <v-col md="4" class="key-label pl-6">
                        <div class="pt-2 font-15px">Space Type</div>
                    </v-col>
                    <v-col md="8 pl-5 pr-7" v-if="!isEditable">
                        <div class="form-input-container lease-detail">
                            <div class="pt-1 pb-2">
                                {{template.unit_type | capitalize }}
                            </div>
                            <div>
                                Default template for this space type: <span class="light-text">{{ template.is_default ? 'Yes' : 'No' }}</span>
                            </div>
                        </div>
                    </v-col>
                    <v-col md="8 pl-9 pr-7" v-else>
                        <div class="form-input-container">
                            <v-select
                                :items="unitTypes"
                                hide-details
                                single-line
                                label="Select Space Type"
                                v-validate="'required'"
                                id="unit_type"
                                name="unit_type"
                                data-vv-as="unit type"
                                v-model="template.unit_type"
                            >
                                <template v-slot:selection="{ item }">
                                    {{item | capitalize }}
                                </template>
                                <template v-slot:item="{ item }">
                                    {{item | capitalize }}
                                </template>

                            </v-select>
                            <span v-show="errors.has('unit_type')" class="status-block error-block field-error">{{ errors.first('unit_type') }}</span>
                        </div>

                        <div class="form-input-container">

                            <v-checkbox
                                :readonly="!isEditable"
                                v-model="template.is_default"
                                id="checkbox-is-default"
                                name="checkbox-is-default"
                                data-name="checkbox-is-default"
                                label="Set this as the default template for this space type"
                            ></v-checkbox>
                        </div>
                    </v-col>
                </v-row>

                <!-- <v-row class="top-border mrl--25px pt-6 pb-6"></v-row> -->

                    <v-row class="top-border mrl--25px">
                        <v-col md="4" class="key-label pl-6">
                            <div class="pt-3 font-15px">Rent</div>
                        </v-col>
                        <v-col md="8 pl-5 pr-7" v-if="!isEditable">
                            <div class="form-input-container lease-detail">
                                <div class="pb-3 pt-3">
                                    Charge Sales Tax on Rent: <span class="light-text">{{ template.tax_rent ? 'Yes' : 'No' }}</span>
                                </div>

                                <div class="pb-3">
                                    Prorate rent in: <span class="light-text">{{ template.prorate_rent ? 'Yes' : 'No' }}</span>
                                </div>

                                <div class="pb-3">
                                    Prorate rent out: <span class="light-text">{{ template.prorate_rent_out ? 'Yes' : 'No' }}</span>
                                </div>

                                <div class="pb-3">
                                    Collect Security Deposit: <span class="light-text">{{ template.security_deposit ? 'Yes' : 'No' }}</span>
                                </div>
                                <div class="pb-3" v-show="template.security_deposit">
                                    Months Rent: <span class="light-text">{{template.security_deposit_months}}</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col md="8 pl-8 pr-7 pb-6" v-else>

                            <div class="form-input-container">
                                <v-checkbox
                                   :readonly="!isEditable"
                                    v-model="template.tax_rent"
                                    id="checkbox-tax-rent"
                                    name="checkbox-tax-rent"
                                    data-name="checkbox-tax-rent"
                                    hide-details
                                    label="Charge Sales Tax on Rent"
                                ></v-checkbox>
                            </div>

                            <div class="form-input-container">
                                <v-checkbox
                                    :readonly="!isEditable"
                                    v-model="template.prorate_rent"
                                    id="checkbox-prorate-rent"
                                    name="checkbox-prorate-rent"
                                    data-name="checkbox-prorate-rent"
                                    hide-details
                                    label="Prorate rent in"
                                ></v-checkbox>
                            </div>

                            <div class="form-input-container">
                                <v-checkbox
                                    :readonly="!isEditable"
                                    v-model="template.prorate_rent_out"
                                    id="checkbox-prorate-rent-out"
                                    name="checkbox-prorate-rent-out"
                                    data-name="checkbox-prorate-rent-out"
                                    hide-details
                                    label="Prorate rent out"
                                ></v-checkbox>
                            </div>

                            <div class="form-input-container">
                                <v-checkbox
                                    name="checkbox-security-deposit"
                                    id="checkbox-security-deposit"
                                    v-model="template.security_deposit"
                                    hide-details
                                    label="Collect Security Deposit"
                                ></v-checkbox>
                            </div>

                            <v-row v-show="template.security_deposit">
                                <v-col cols="2 pt-0 pb-0 offset-md-1 pl-0">
                                    <div class="form-input-container" :class="{'has-error': errors.has('security_deposit_months') }">
                                        <v-text-field
                                            class="text-right dir-rtl"
                                            single-line
                                            v-model="template.security_deposit_months"
                                            v-validate ="{ required: this.template.security_deposit === true, integer: true }"
                                            id="security_deposit_months"
                                            name="security_deposit_months"
                                            data-vv-as="security deposit months"
                                        ></v-text-field>
                                        <span v-show="errors.has('security_deposit_months')" class="status-block error-block field-error">{{ errors.first('security_deposit_months') }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="8 pt-6 pb-0"><div class="small-input-label">Months Rent</div></v-col>
                            </v-row>

                        </v-col>
                    </v-row>

                    <v-row class="top-border mrl--25px">
                        <v-col md="4" class="key-label pl-6">
                            <div class="pt-1 font-15px">Lease Type</div>
                        </v-col>
                        <v-col md="8 pl-5 pr-4">
                            <div class="form-input-container lease-detail pt-1" v-if="!isEditable">
                                {{template.lease_type}}
                            </div>
                            <div class="form-input-container" v-else>
                                <v-select
                                    solo
                                    flat
                                    :items="leaseTypes"
                                    hide-details
                                    single-line
                                    label="Select Lease Type"
                                    id="lease_type"
                                    name="lease_type"
                                    data-vv-as="lease type"
                                    v-validate ="'required'"
                                    v-model="template.lease_type"
                                ></v-select>
                                <span v-show="errors.has('lease_type')" class="status-block error-block field-error pl-3">{{ errors.first('lease_type') }}</span>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="top-border mrl--25px" v-show="template.lease_type == 'Fixed Length' && (isEditable || template.lease_duration)">
                        <v-col md="4" class="key-label pl-6">
                            <div class="pt-1 font-15px">Lease Duration</div>
                        </v-col>
                        <v-col md="8 pl-5 pr-4" v-if="!isEditable">
                            <div class="lease-detail">
                                {{template.lease_duration}} &nbsp; {{template.lease_duration_type}}
                            </div>
                        </v-col>
                        <v-col md="8 pl-8 pr-4" v-else>
                            <v-row>
                                <v-col cols="2 pt-0 pb-0">
                                    <div class="form-input-container" :class="{'has-error': errors.has('lease_duration') }">
                                        <v-text-field
                                            class="text-right"
                                            single-line
                                            hide-details
                                            v-validate ="{ required: this.template.lease_type == 'Fixed Length', integer: true }"
                                            name="lease_duration"
                                            id="lease_duration"
                                            data-vv-as="lease duration"
                                            v-model="template.lease_duration"
                                        ></v-text-field>
                                    </div>
                                </v-col>
                                <v-col cols="4 pt-0 pb-0">
                                    <div class="form-input-container">
                                        <v-select
                                            :items="lease_duration_types"
                                            hide-details
                                            single-line
                                            label="Choose One"
                                            v-model="template.lease_duration_type"
                                        ></v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-col class=" pl-0 pb-0">
                                <span v-show="errors.has('lease_duration')" class="status-block error-block field-error">{{ errors.first('lease_duration') }}</span>
                            </v-col>
                        </v-col>
                    </v-row>

                    <v-row class="top-border mrl--25px">
                        <v-col md="4" class="key-label pl-6">
                            <div class="pt-2 font-15px">Bill On</div>
                        </v-col>
                        <v-col md="8 pl-5 pr-7" v-if="!isEditable">
                            <div class="pt-2 pb-2 lease-detail">
                                {{template.bill_day}}
                            </div>
                            <div class="pb-2 lease-detail">
                                Auto Email Monthly Statement: <span class="light-text">{{template.email_statement  ? 'Yes' : 'No' }}</span>
                            </div>
                        </v-col>
                        <v-col md="8 pl-9 pr-7" v-else>
                            <div class="form-input-container">
                                <v-select
                                    :readonly="!isEditable"
                                    :items="bill_on_options"
                                    v-model="template.bill_day"
                                    hide-details
                                    single-line
                                    label="Select Option"
                                    id="bill_on"
                                    name="bill_on"
                                ></v-select>
                            </div>
                            <div class="form-input-container">
                                <v-checkbox
                                    :readonly="!isEditable"
                                    v-model="template.email_statement"
                                    id="checkbox-email-statement"
                                    name="checkbox-email-statement"
                                    data-name="checkbox-email-statement"
                                    label="Auto Email Monthly Statement"
                                ></v-checkbox>
                            </div>
                        </v-col>
                    </v-row>

<!--                    <v-row class="top-border mrl&#45;&#45;25px" v-if="template.terms || isEditable">-->
<!--                        <v-col md="4" class="key-label pl-6">-->
<!--                            <div class="pt-2 font-15px">Lease Terms</div>-->
<!--                        </v-col>-->
<!--                        <v-col md="8 pl-5 pr-7">-->
<!--                            <div class="form-input-container" :class="{'has-error': errors.has('terms') }">-->
<!--                                <v-textarea-->
<!--                                    :readonly="!isEditable"-->
<!--                                    dense-->
<!--                                    solo-->
<!--                                    flat-->
<!--                                    rows="2"-->
<!--                                    hide-details-->
<!--                                    name="terms"-->
<!--                                    id="terms"-->
<!--                                    v-model="template.terms"-->
<!--                                    v-validate="'max:1000'"-->
<!--                                    placeholder="Enter Terms">-->
<!--                                </v-textarea>-->
<!--                                <span v-show="errors.has('terms')" class="status-block error-block field-error pl-3">{{ errors.first('terms') }}</span>-->
<!--                            </div>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
                </div>
            </div>
            <v-divider></v-divider>
            <div class="slide-out-tenant-header bg-white grey-border py-0 pt-4 pb-1" v-if="isEditable">
                <div class="slide-out-info ml-0">
                    <v-row>
                        <v-col cols="9" class="pt-0">
                        </v-col>
                        <v-col cols="3" class="pt-0 d-flex align-center justify-end">
                            <a class="add-role-btn mr-2"  @click="isEditable = false">Cancel</a>
                            <hb-btn :disabled="isLoading($options.name)" @click="save" color="primary">Save</hb-btn>
                            <span v-show="isLoading($options.name)" >
                                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-card>


            <!--<div class="interaction">-->


                <!--<div class="col-xs-12 col-sm-4">-->
                    <!--<label>Security Deposit</label>-->
                <!--</div>-->
                <!--<div class="col-xs-12 col-sm-8" :class="{'has-error': errors.has('security deposit') }">-->

                    <!--<div class="form-inline narrow">-->
                        <!--<input name="duration" v-model="template.security_deposit_months" class="form-control" placeholder="1" />-->

                    <!--</div>-->

                    <!--<div class="form-inline">-->
                        <!--Months-->
                    <!--</div>-->

                    <!--<span v-show="errors.has('security deposit')" class="help-block ">{{ errors.first('security deposit') }}</span>-->
                <!--</div>-->
            <!--</div>-->

            <!--<div class="interaction">-->


                <!--<div class="row lined">-->
                    <!--<div class="col-xs-12 col-sm-4">-->
                        <!--<label>Lease Type</label>-->
                    <!--</div>-->
                    <!--<div class="col-xs-12 col-sm-8">-->
                        <!--<dropdown :list="leaseTypes" v-model="template.lease_type" ></dropdown>-->

                        <!--<span v-show="errors.has('lease type')" class="help-block ">{{ errors.first('lease type') }}</span>-->
                    <!--</div>-->
                <!--</div>-->


                <!--<div class="row lined" v-show="template.lease_type == 'Fixed Length'">-->
                    <!--<div class="col-xs-12 col-sm-4">-->
                        <!--<label>Lease Duration</label>-->
                    <!--</div>-->
                    <!--<div class="col-xs-12 col-sm-8" :class="{'has-error': errors.has('duration') }">-->
                        <!--<div class="form-inline narrow">-->

                            <!--<input name="duration" v-model="template.lease_duration" class="form-control" placeholder="1" />-->

                        <!--</div>-->
                        <!--<div class="form-inline std">-->
                            <!--<dropdown v-model="template.lease_duration_type" :list="lease_duration_types"  ></dropdown>-->
                        <!--</div>-->
                        <!--<span v-show="errors.has('duration')" class="help-block ">{{ errors.first('duration') }}</span>-->
                    <!--</div>-->



                    <!--<div class="row lined">-->
                        <!--<div class="col-xs-12 col-sm-4">-->
                            <!--<label>Bill On:</label>-->
                        <!--</div>-->
                        <!--<div class="col-xs-12 col-sm-8">-->
                            <!--<dropdown :list="bill_on_options" v-model="template.bill_day" ></dropdown>-->
                        <!--</div>-->
                    <!--</div>-->

                    <!--<div class="row lined">-->
                        <!--<div class="col-xs-12 col-sm-4">-->
                            <!--<label>Gate Code Format</label>-->
                        <!--</div>-->
                        <!--<div class="col-xs-12 col-sm-8" :class="{'has-error': errors.has('code_format') }">-->

                            <!--<div class="form-inline narrow">-->

                                <!--<input name="code_format" v-validate="'numeric'" v-model="template.gate_code_length" placeholder="4" class="form-control" />-->

                            <!--</div>-->
                            <!--<div class="form-inline">-->
                                <!--<dropdown v-model="template.gate_code_format" :list="gate_code_formats"  ></dropdown>-->
                            <!--</div>-->

                            <!--<span v-show="errors.has('gate_code_format')" class="help-block ">{{ errors.first('code_format') }}</span>-->

                        <!--</div>-->
                    <!--</div>-->

                    <!--<div class="row lined">-->
                        <!--<div class="col-xs-12 col-sm-4">-->
                            <!--<label>Additional Charges</label>-->

                        <!--</div>-->
                        <!--<div class="col-xs-12  col-sm-8">-->
                            <!--<a @click.prevent="addService('lease')" class="btn btn-std">-->
                                <!--<strong><i class="fa fa-plus"></i>&nbsp;&nbsp;Add a charge</strong>-->
                            <!--</a>-->
                            <!--<div style="border-top: 1px solid #e2e2e2; border-left: 1px solid #e2e2e2; border-right: 1px solid #e2e2e2; padding: 0 15px; margin-top:10px;" v-show="template.Services.length">-->

                                <!--<div class="row row-head lined" >-->
                                    <!--<div class="col-xs-8"><strong>Name</strong></div>-->
                                    <!--<div class="col-xs-2 text-right"><strong>Price</strong></div>-->
                                <!--</div>-->
                                <!--<div class="row lined" v-for="s in template.Services">-->
                                    <!--<div class="col-xs-8">{{s.Product.name}}</div>-->
                                    <!--<div class="col-xs-2 text-right">${{s.price }}</div>-->
                                    <!--<div class="col-xs-2"><i @click="removeService(s, 'lease')"  class="fa fa-remove"></i></div>-->
                                <!--</div>-->
                            <!--</div>-->

                        <!--</div>-->
                    <!--</div>-->



                    <!--<div class="row lined">-->
                        <!--<div class="col-xs-12 col-sm-4">-->
                            <!--<label>Reservation Charges</label>-->
                            <!--<p>Enter fees associated with reserving a unit. These will be charged if someone makes a reservation</p>-->
                        <!--</div>-->
                        <!--<div class="col-xs-12  col-sm-8">-->
                            <!--<a @click.prevent="addService('reservation')" class="btn btn-std"><strong><i class="fa fa-plus"></i>&nbsp;&nbsp;Add a charge</strong></a>-->

                            <!--<div style="border-top: 1px solid #e2e2e2; border-left: 1px solid #e2e2e2; border-right: 1px solid #e2e2e2; padding: 0 15px; margin-top:10px;" v-show="template.ReservationServices.length">-->

                                <!--<div class="row row-head lined" >-->
                                    <!--<div class="col-xs-8"><strong>Name</strong></div>-->
                                    <!--<div class="col-xs-2 text-right"><strong>Price</strong></div>-->
                                <!--</div>-->
                                <!--<div class="row lined" v-for="s in template.ReservationServices">-->
                                    <!--<div class="col-xs-8">{{s.Product.name}}</div>-->
                                    <!--<div class="col-xs-2 text-right">${{s.price }}</div>-->
                                    <!--<div class="col-xs-2"><i @click="removeService(s, 'reservation')"  class="fa fa-remove"></i></div>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->







                    <!--<div class="row lined">-->
                        <!--<div class="col-xs-12 col-sm-4">-->
                            <!--<label>Application Charges</label>-->
                            <!--<p>Enter fees associated with applying for a unit. These will be charged if someone applies</p>-->
                        <!--</div>-->
                        <!--<div class="col-xs-12  col-sm-8">-->
                            <!--<a @click.prevent="addService('application')" class="btn btn-std"><strong><i class="fa fa-plus"></i>&nbsp;&nbsp;Add a charge</strong></a>-->

                            <!--<div style="border-top: 1px solid #e2e2e2; border-left: 1px solid #e2e2e2; border-right: 1px solid #e2e2e2; padding: 0 15px; margin-top:10px;" v-show="template.ApplicationServices.length">-->

                                <!--<div class="row row-head lined" >-->
                                    <!--<div class="col-xs-8"><strong>Name</strong></div>-->
                                    <!--<div class="col-xs-2 text-right"><strong>Price</strong></div>-->
                                <!--</div>-->
                                <!--<div class="row lined" v-for="s in template.ReservationServices">-->
                                    <!--<div class="col-xs-8">{{s.Product.name}}</div>-->
                                    <!--<div class="col-xs-2 text-right">${{s.price }}</div>-->
                                    <!--<div class="col-xs-2"><i @click="removeService(s, 'application')"  class="fa fa-remove"></i></div>-->
                                <!--</div>-->
                            <!--</div>-->

                        <!--</div>-->
                    <!--</div>-->

                    <!--<div class="row lined">-->
                        <!--<div class="col-xs-12 col-sm-4">-->
                            <!--<label>&nbsp;</label>-->
                        <!--</div>-->
                        <!--<div class="col-xs-12 col-sm-8">-->
                            <!--<div class="checkbox">-->
                                <!--<label>-->
                                    <!--<input type="checkbox" v-model="template.email_statement" />-->
                                    <!--&nbsp;Auto Email Monthly Invoice?-->
                                <!--</label>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->

                    <!--<div class="row lined">-->
                        <!--<div class="col-xs-12 col-sm-4">-->
                            <!--<label>Lease Terms</label>-->
                        <!--</div>-->
                        <!--<div class="col-xs-12  col-sm-8">-->
                            <!--<textarea v-model="template.terms" class="form-control" rows="10"></textarea>-->
                        <!--</div>-->
                    <!--</div>-->


                    <!--<div class="row">-->
                        <!--<div class="col-xs-12">-->
                            <!--<status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>-->

                            <!--<status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>-->
                            <!--<button class="btn btn-dark" @click="save">Save</button>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->


                <!--<modal v-if="showAddService" size="lg" allow-click-away="false" @close="showAddService = false">-->
                    <!--<h3 slot="header">Add Charge</h3>-->
                    <!--<add-service size="lg" slot="body" @addProduct="showAddProduct = true;" @close="showAddService = false;" @save="saveService">-->
                    <!--</add-service>-->
                <!--</modal>-->
            <!--</div>-->
        <!--</template>-->

</template>


<script type="text/babel">


    import Dropdown from "../../assets/Dropdown.vue";
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import { EventBus } from '../../../EventBus.js';

    export default{
        name: "LeaseValues",
        data(){
            return {
                open: '',
                template: {
                    security_deposit: false,
                    unit_type: '',
                    is_default: false,
                    security_deposit_months: "1",
                    lease_type: 'Month To Month',
                    bill_day: '1st of the Month',
                    lease_duration: 1,
                    lease_duration_type: 'Years',
                    terms: '',
                    email_statement: true,
                    tax_rent: false,
                    prorate_rent: true,
                    prorate_rent_out: true
                },
                leaseTypes: [
                    'Fixed Length',
                    'Month to Month'
                ],
                unitTypes: [
                    'residential',
                    'storage',
                    'parking'
                ],
                deposit_options: [
                    "1 Month's Rent",
                    "2 Month's Rent",
                    "Custom Amount"
                ],
                lease_duration_types: [
                    'Years',
                    'Months',
                    'Days'
                ],
                gate_code_formats: [
                    'Numeric Characters',
                    'Alphanumeric Characters'
                ],
                bill_on_options: [
                    '1st of the Month',
                    'Anniversary'
                ],
                showAddService: false,
                addServiceType: null,
                addChecklistItem: false,
                isEditable : false
            }
        },
        props: ['template_id', 'templateObj'],
        components:{
            Dropdown,
            Status,
            Loader
        },
        created(){
            if(this.templateObj){
                this.template = JSON.parse(JSON.stringify(Object.assign({}, this.templateObj)));
            }
        },
        methods:{
            save(){
                var _this = this;
                this.errorClear(this.$options.name);
                this.$validator.validateAll().then(function(status){

                    if(!status) throw "Validation Error.";
                    if(_this.template_id){
                        api.put(_this, api.TEMPLATES + _this.template_id,  _this.template).then(r => {
                            _this.isEditable = false;
                            _this.successSet(_this.$options.name, "Lease template has been successfully updated.");
                            EventBus.$emit('HB-Lease-Template:Name-Description', {name : _this.template.name, description : _this.template.description});

                        });
                    } else {
                        api.post(_this, api.TEMPLATES,  _this.template).then(r => {
                            _this.$emit('saved', r.id);
                            _this.successSet(_this.$options.name, "Lease template has been successfully saved.");

                        });
                    }

                }).catch(function(err){
                    console.log(err);
                    _this.errorSet(_this.$options.name, "You have errors on your form.  Please fix them before continuing");
                });
            },
            clearForm(){
                this.template = {
                    security_deposit: false,
                    unit_type: '',
                    is_default: false,
                    property_id: '',
                    security_deposit_months: "1",
                    lease_type: 'Month To Month',
                    bill_day: '1st of the Month',
                    lease_duration: 1,
                    lease_duration_type: 'Years',
                    terms: '',
                    email_statement: true,
                    gate_code_length: '',
                    gate_code_format: '',
                    prorate_rent: true,
                    prorate_rent_out: true
                }
            }
        },
        watch:{
            template_id(val){
                this.errorClear(this.$options.name);
                this.clearForm();
                if(val){
                    this.fetchData();
                }
            }
        }

    }


</script>

<style scoped>
    .form-section {
        border-bottom: none;
    }
    .grey-border{
        border-bottom : 1px solid #DFE3E8;;
    }
    .lease-heading{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
        .mrl--25px{
        margin-left: -25px;
        margin-right: -25px;
    }
    .key-label{
      background: #F4F6F8;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
    .bg-white{
        background-color: #FFFFFF;
    }
    .no-clickable{
        pointer-events:none
    }
    .light-text{
        color: rgba(0, 0, 0, 0.5)
    }
    .lease-detail{
        font-size: 15px;
    }
</style>

<style>
    .dir-rtl input{
        direction: rtl;
    }
</style>
