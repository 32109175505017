<template>

    <div>
         <v-dialog content-class="lease-content" v-model="isOpened" persistent max-width="800" scrollable>
            <v-card>
              <v-card-title>
                  <v-row class="ma-0">
                      <v-col cols="10 pa-0" > {{ isEdit ? 'Edit' : 'Add' }} Checklist Item</v-col>
                      <v-col cols="2" class="pa-0 text-right">
                          <v-icon @click="closeDialog()">mdi-close</v-icon>
                      </v-col>
                  </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pb-0">
                  <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                  <v-row class="mrl--25px pl-3 pr-3 border-bottom">
                      <v-col cols="12" class="pb-6" :class="{ 'pt-1' : errorHas($options.name), 'pt-6' : !errorHas($options.name)}">
                          <p class="toggle-p mb-0">Add documents to be signed, require files to be uploaded, or set up other tasks for your new customers.</p>
                      </v-col>
                  </v-row>

                  <v-row class="top-border mrl--25px">
                      <v-col md="4" class="key-label pl-6">
                          <div class="pt-2 font-15px">Name</div>
                      </v-col>
                      <v-col md="8 pl-5 pr-7">
                          <div class="form-input-container" :class="{'has-error': errors.has('name') }">
                              <v-text-field
                                  solo
                                  flat
                                  hide-details
                                  v-model="checklist.name"
                                  v-validate="'required|max:45'"
                                  :class="{'has-error': errors.has('name') }"
                                  name="name"
                                  id="name"
                                  placeholder="Enter Name">
                              </v-text-field>
                              <span v-show="errors.has('name')" class="status-block error-block field-error pl-3">{{ errors.first('name') }}</span>
                          </div>
                      </v-col>
                  </v-row>

                  <v-row class="top-border mrl--25px">
                      <v-col md="4" class="key-label pl-6">
                          <div class="pt-2 font-15px">Description</div>
                      </v-col>
                      <v-col md="8 pl-5 pr-7">
                          <div class="form-input-container" :class="{'has-error': errors.has('description') }">
                              <v-textarea
                                  dense
                                  solo
                                  flat
                                  hide-details
                                  rows="2"
                                  v-model="checklist.description"
                                  v-validate="'max:1000'"
                                  id="description"
                                  name="description"
                                  placeholder="Enter Description">
                              </v-textarea>
                              <span v-show="errors.has('description')" class="status-block error-block field-error pl-3">{{ errors.first('description') }}</span>
                          </div>
                      </v-col>
                  </v-row>

                  <v-row class="top-border mrl--25px">
                      <v-col md="4" class="key-label pl-6">
                          <div class="pt-2 font-15px">Upload Source</div>
                      </v-col>
                      <v-col md="8 pl-8 pr-7 pb-4">
                          <div class="form-input-container">
                              <v-select
                                  dense
                                  :items="upload_types"
                                  name="upload_type"
                                  v-model="upload_type"
                                  hide-details
                                  single-line
                                  label="Upload Source"
                                  id="upload_type"
                              ></v-select>
                          </div>

<!--                                <div class="form-input-container">-->
<!--                                    <v-checkbox -->
<!--                                        v-model="checklist.require_all"-->
<!--                                        id="require_all"-->
<!--                                        name="require_all"-->
<!--                                        data-name="require_all"-->
<!--                                        label="Require all tenants to complete individually"-->
<!--                                    ></v-checkbox>-->
<!--                                </div>-->
                      </v-col>
                  </v-row>

                  <v-row class="top-border mrl--25px" v-show="upload_type == 'Document Provided by User'">
                      <v-col md="4" class="key-label pl-6">
                          <div class="pt-2 font-15px">Document Type</div>
                      </v-col>
                      <v-col md="8 px-5 pb-4">
                          <div class="form-input-container">
                              <v-select
                                  solo
                                  flat
                                  dense
                                  :items="document_types"
                                  id="document_type"
                                  name="document_type"
                                  item-text="name"
                                  item-value="id"
                                  v-model="document_type"
                                  class="lc-light"
                                  data-vv-as="document type"
                                  return-object
                                  v-validate="{required: upload_type == 'Document Provided by User'}"
                                  hide-details
                                  single-line
                                  label="Choose Document Type"
                              ></v-select>
                              <span v-show="errors.has('document_type')" class="status-block error-block field-error">{{ errors.first('document_type') }}</span>
                          </div>
                      </v-col>
                  </v-row>

                  <v-row class="top-border mrl--25px" v-show="upload_type == 'Electronically Signed Document'">
                      <v-col md="4" class="key-label pl-6">
                          <div class="pt-2 font-15px">Choose a Document</div>
                      </v-col>
                      <v-col md="8 px-5 pb-4">
                          <div class="form-input-container">
                              <v-select
                                  solo
                                  flat
                                  dense
                                  :items="documents"
                                  id="document_id"
                                  name="document_id"
                                  item-text="name"
                                  item-value="id"
                                  labelField="name"
                                  v-model="document"
                                  class="lc-light"
                                  data-vv-as="document"
                                  return-object
                                  v-validate="{required: checklist.upload_type == 'Electronically Signed Document'}"
                                  hide-details
                                  single-line
                                  label="Choose Document"
                              ></v-select>
                              <span v-show="errors.has('document_id')" class="status-block error-block field-error">{{ errors.first('document_id') }}</span>
                          </div>
                      </v-col>
                  </v-row>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-3 text-right">
                    <v-col class="pt-1 pb-1">
                        <a class="close-link" @click="closeDialog()">Cancel</a>
                        <v-btn color="primary" :disabled="isLoading($options.name)" large @click="save">Save</v-btn>
                        <span v-show="isLoading($options.name)">
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                        </span>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <div class="modal-footer">
            <a @click.prevent="$emit('close')" class="close-link">Cancel</a>
            <button class="w-button primary-btn" :disabled="isLoading($options.name)" @click.prevent="save">Save</button>
            <span v-show="isLoading($options.name)" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>

        </div> -->

        </div>
</template>
<script type="text/babel">
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';

    import Dropdown from '../../assets/Dropdown.vue';
    import DropdownConfirmation from '../../assets/DropdownConfirmation.vue';


    export default {

        name: "AddChecklistItem",
        data() {
            return {
                checklist:{
                    id: '',
                    template_id:'',
                    document_type_id:'',
                    document_id:'',
                    name: '',
                    description: '',
                    require_all: false

                },
                upload_type: 'No Upload Required',
                documents: [],
                document_types: [],
                document: null,
                document_type: null,
                newDocumentType: '',
                upload_types: [
                    'No Upload Required',
                    'Document Provided by User',
                    'Electronically Signed Document'
                ],
                saveType: 'template',
                isOpened : true,
                isEdit : false
            }
        },
        components:{
            Loader,
            Status,
            Dropdown,
            DropdownConfirmation
        },
        props:[
            'selected',
            'template_id',
            'type'
        ],
        created(){
            if(this.type) this.saveType = this.type;

            if(this.selected && this.selected.id){
                this.isEdit = true;
                this.checklist = JSON.parse(JSON.stringify(this.selected));
                if(this.checklist.document_id) this.upload_type = 'Electronically Signed Document';
                if(this.checklist.document_type_id) this.upload_type = 'Document Provided by User';
            }

            this.fetchDocumentTypes();
            this.fetchDocuments();

        },
        computed:{

        },
        methods:{

            save(){
                this.errorClear(this.$options.name);
                this.$validator.validateAll().then(status => {
                    if(!status) throw "Validation Error";
                    var data = {
                        document_type_id: null,
                        document_id: null,
                        name: this.checklist.name,
                        description: this.checklist.description,
                        require_all: this.checklist.require_all
                    };
                    if(this.upload_type == 'Document Provided by User'){
                        data.document_type_id = this.document_type.id;
                    }

                    if(this.upload_type == 'Electronically Signed Document'){
                        data.document_id = this.document.id;
                    }
                    if(this.upload_type == 'No Upload Required'){
                        data.document_id = null;
                        data.document_type_id = null;
                    }

                    if(this.saveType == 'template'){
                        if(this.selected.id){
                            return api.put(this,api.TEMPLATES + this.template_id + '/checklist/' + this.selected.id, data).then(r => {
                                this.$emit('saved');
                                this.$emit('showSuccess', data.name, true);
                            })
                        } else {
                            return api.post(this,api.TEMPLATES + this.template_id + '/checklist', data).then(r => {
                                this.$emit('saved');
                                this.$emit('showSuccess', data.name, false);
                            })
                        }
                    } else {
                        this.$emit('save', data);
                    }


                }).catch(err => {
                    console.log(err);
                    console.log(err.stack);
                    this.errorSet(this.$options.name, err);
                });
            },



            fetchDocuments(){
                api.get(this, api.DOCUMENT).then(r => {
                    this.documents =  r.documents;
                    if(this.checklist.document_id){
                        var d = this.documents.filter(doc => doc.id == this.checklist.document_id);
                        if(d.length){
                            this.document = d[0];
                        }
                    }
                });
            },
            fetchDocumentTypes(){
                api.get(this, api.DOCUMENT_TYPES).then(r => {

                    if(r.types){
                        this.document_types = r.types;
                        if(this.checklist.document_type_id){
                            var d = this.document_types.filter(doc => doc.id == this.checklist.document_type_id);
                            if(d.length){
                                this.document_type = d[0];
                            }
                        }

                    }
                });
            },
            saveDocumentType(){
                if(!this.newDocumentType) return;
                api.post(this, api.DOCUMENT_TYPES, {name: this.newDocumentType}).then(r => {
                    this.fetchDocumentTypes();
                });
            },
            closeDialog(){
                this.isOpened = false;
                setTimeout(() => {
                    this.$emit('close');
                }, 200);
            }

        },

    }

</script>

<style lang="scss" scoped>
    .mrl--25px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
</style>
