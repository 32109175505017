<template>
    <hb-modal v-model="dialog" show-help-link size="large" title="Add Product" @close="$emit('close')">
        <template v-slot:content>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <v-row class="ma-0 pa-0">
                <v-col md="4" class="key-label pl-6 pt-5">
                    <div class="pt-2 font-15px">Choose Product</div>
                </v-col>
                <v-col md="8" class="pa-3 pl-5 pr-7">
                    <div class="form-input-container">
                        <v-autocomplete
                            solo 
                            flat
                            v-model="product"
                            :items="products"
                            v-validate="'required'"                                        
                            item-text="name" 
                            item-value="id"
                            return-object
                            hide-details 
                            single-line
                            dense
                            name="product_id"
                            data-vv-name="product_id"
                            id="product_id"
                            data-vv-as="product"
                            label="Select a Product"
                        ></v-autocomplete>
                        <span v-show="errors.has('product_id')" class="status-block error-block field-error">{{ errors.first('product_id') }}</span>
                    </div>
                </v-col>
            </v-row>


            <v-row class="top-border ma-0 pa-0">
                <v-col md="4" class="key-label pl-6">
                    <div class="pt-2 font-15px">Price</div>
                </v-col>
                <v-col md="8 pl-8 pr-7 pb-6 pt-3">
                    <div class="form-input-container" :class="{'has-error': errors.has('price') }">
                        <v-text-field
                            dense
                            hide-details
                            v-model="service.price"
                            v-validate="'required|decimal:2|max_value:999999|min_value:0'"
                            name="price"
                            id="price"
                            placeholder="Enter Price">
                        </v-text-field>
                        <span v-show="errors.has('price')" class="status-block error-block field-error">{{ errors.first('price') }}</span>
                    </div>
                    <div class="form-input-container">
                        <v-checkbox 
                            v-model="service.recurring"
                            id="recurring"
                            name="recurring"
                            data-name="recurring"
                            hide-details 
                            label="This is recurring charge" 
                        ></v-checkbox>
                        <span v-show="errors.has('recurring')" class="status-block error-block field-error">{{ errors.first('recurring') }}</span>
                    </div>

                    <!-- <div class="form-input-container">
                        <v-checkbox 
                            id="collect-checkbox"
                            name="collect-checkbox" 
                            hide-details 
                            label="Prorate this charge"
                        ></v-checkbox>
                    </div> -->



                    <div class="form-input-container">
                        <v-checkbox 
                            v-model="service.prorate"
                            id="prorate"
                            name="prorate"
                            data-name="prorate"
                            hide-details 
                            label="Prorate rent in" 
                        ></v-checkbox>
                        <span v-show="errors.has('prorate')" class="status-block error-block field-error">{{ errors.first('prorate') }}</span>
                    </div>

                    <div class="form-input-container">
                        <v-checkbox
                            v-model="service.prorate_out"
                            id="prorate_out"
                            name="prorate_out"
                            data-name="prorate_out"
                            hide-details 
                            label="Prorate rent out" 
                        ></v-checkbox>
                        <span v-show="errors.has('prorate')" class="status-block error-block field-error">{{ errors.first('prorate_out') }}</span>
                    </div>

                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0">
                <v-col md="4" class="key-label pl-6">
                    <div class="pt-2 font-15px">Quantity</div>
                </v-col>
                <v-col md="8 pl-5 pr-7 pb-6">
                    <div class="form-input-container" :class="{'has-error': errors.has('qty') }">
                        <v-text-field
                            solo
                            flat
                            hide-details
                            v-model="service.qty"
                            v-validate="'required|integer|min_value:1|max_value:9999'"
                            name="qty"
                            id="qty"
                            placeholder="Enter Quantity">
                        </v-text-field>
                        <span v-show="errors.has('qty')" class="status-block error-block field-error pl-3 pt-1">{{ errors.first('qty') }}</span>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="save">Save</hb-btn>
            <span v-show="isLoading($options.name)">
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>  
        </template>
    </hb-modal>
</template>
<script type="text/babel">

    import AutoComplete from '../../assets/Autocomplete.vue';
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';
    import api from '../../../assets/api.js';
    export default {
        name: "AddTemplateService",
        data() {
            return {
                type: "lease",
                service:{
                    id: null,
                    optional: null,
                    product_id: '',
                    template_id: '',
                    price: '',
                    qty: 1,
                    recurring: false,
                    prorate: false,
                    prorate_out: false
                },
                products:[],
                product:{
                    name: '',
                    id: ''
                },
                showAddProduct: false,
                isOpened : true
            }
        },
        components:{
            AutoComplete,
            Datepicker,
            Loader,
            Status
        },
        created(){

            if(this.selected && this.selected.id){
                this.product = JSON.parse(JSON.stringify(this.selected.Product));
            }
            this.service = JSON.parse(JSON.stringify(this.selected));
            this.getProducts();
        },
        computed:{
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
        },
        filters:{

        },
        methods: {
            closeWindow(){
                this.isOpened = false
                setTimeout(() => {
                    this.$emit('close')
                }, 250);
            },
            save(){

                this.errorClear(this.$options.name);

                this.$validator.validateAll().then(status => {
                    if(!status) throw "Validation Error";

                    var save = {
                        optional: this.service.optional,
                        product_id: this.service.product_id,
                        price: this.service.price,
                        qty: 1,
                        recurring: !!this.service.recurring,
                        prorate: !!this.service.prorate,
                        prorate_out: !!this.service.prorate_out
                    }

                    if(this.service.id){
                        api.put(this, api.TEMPLATES + this.template_id + '/services/' + this.type + '/' +  this.service.id, save).then(r => {
                            this.$emit('saved');                            
                            this.closeWindow();
                            this.$emit('showSuccess', this.product.name, true);
                        });

                    } else {
                        api.post(this, api.TEMPLATES + this.template_id + '/services/' + this.type, save).then(r => {
                            this.$emit('saved');
                            this.closeWindow();
                            this.$emit('showSuccess', this.product.name);
                        });
                    }

                }).catch(function(err){
                    console.log(this.$validator);
                    this.errorSet(_this.$options.name, "You have errors on your form.  Please fix them before continuing");
                });

            },

            getProducts(search = ''){

                search = search || '';
                this.product.name = search;

                var params = {
                    type: 'product',
                    all: true,
                    search: search
                };

                api.get(this, api.SEARCH_PRODUCTS, params).then(r => {
                    this.products = r.products;
                });

            },
            clearProduct(){
                this.service.product_id = null;
                this.product = {
                    name: '',
                    id: ''
                };
                this.service.price = '';
            },
            saveNewProduct(){
                this.clearProduct();
                this.$emit('addProduct');
            }


        },
        watch:{
            product(p){
                this.service.product_id = p.id;
                if(!this.service.price) this.service.price = p.price;
                if(!this.service.prorate) this.service.prorate = p.prorate;
                if(!this.service.prorate_out) this.service.prorate_out = p.prorate_out;
            }
        },
        props:[
            'selected',
            'template_id',
            'value'
        ]
    }
</script>

<style scoped>
    .mrl--25px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .no-top-border{
        border-top: none;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
    .border-bottom{
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
</style>
