<template>
    <div class="pt-0">
        <v-col cols="11" class="pa-0" >
            <v-tabs background-color="transparent" class="hb-text-light pl-6" color="#101318">
                <v-tabs-slider color="#00848E"></v-tabs-slider>
                <v-tab class="text-capitalize" v-for="(item, index) in tabOptions" :key="index" @click="setActiveTab(item.value)">{{item.title}}</v-tab>
            </v-tabs>
        </v-col>
        <default-values v-if="view == 'defaults' && template" :template_id="templateId" :template-obj="template" ></default-values>
        <products v-if="view == 'products'" :template_id="templateId"></products>
        <insurance v-if="view == 'insurance'" :template_id="templateId"></insurance>
        <checklist v-if="view == 'checklist'" :template_id="templateId"></checklist>
    </div>
</template>

<script type="text/babel">

    import DefaultValues from "./DefaultValues.vue"
    import Products from "./Products.vue"
    import Insurance from "./Insurance.vue"
    import Checklist from "./Checklist.vue"
    import api from '../../../assets/api.js';
    import { EventBus } from '../../../EventBus.js';


    export default{
        name: "ConfigureLEase",
        data(){
            return {
                view: null,
                tabOptions : [
                    {
                        title : 'Details',
                        value : 'defaults'
                    },
                    {
                        title : 'Products',
                        value : 'products'
                    },
                    {
                        title : 'Documents, etc.',
                        value : 'checklist'
                    }
                ],
                activeTabIndex : 0,
                templateId : '',
                template : null
            }
        },
        props: ['show', 'leaseTemeplateId'],
        components:{
            DefaultValues,
            Products,
            Insurance,
            Checklist
        },
        created(){
            this.view = 'defaults';
            this.templateId = this.leaseTemeplateId;
            this.fetchData();
            EventBus.$on('HB-Lease-Template:Active-Tab', this.setActiveView);
            EventBus.$on('HB-Lease-Templates-list', this.eventEmitRemoveSelected);
        },
        methods:{

            setActiveTab(value, index){
                this.view = value;
                this.activeTabIndex = index;

               
            },
            fetchData(){
                api.get(this, api.TEMPLATES + this.templateId).then(r => {
                    this.template = r.template;
                    EventBus.$emit('HB-Lease-Template:Name-Description', {name : r.template.name, description : r.template.description});
                });
            },
            setActiveView(tab){
                this.view = tab;
               
            },
            eventEmitRemoveSelected(){
                this.$emit('removeSelected');
            }
        },
        destroyed() {
            EventBus.$off('HB-Lease-Template:Active-Tab', this.setActiveView);
            EventBus.$off('HB-Lease-Templates-list', this.eventEmitRemoveSelected);
            this.$emit('primaryTabs', true)
            EventBus.$emit('HB-Lease-Template:Name-Description', null);
        },
    }
</script>

<style scoped>

</style>

<style>

    .main-temp-heading{
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
        color: #101318;
    }
</style>
