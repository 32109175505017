<template>
    <v-card elevation="1">
        <div class="slide-out-section pa-0">

<!--            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>-->
<!--            <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>-->

            <div class="form-section bg-white pa-0">

                <v-col class="ma-0 pt-3 pb-5 pl-0 pr-0">
                    <h2 class="font-weight-medium pl-5 pb-3">Documents, etc</h2>
                    <v-divider></v-divider>
                </v-col>

                <div class="px-6 pb-6">
                    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                    <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
                    <v-row class="mt-1 mb-6 px-4">
                        <v-col cols="9" class="ma-0 pa-0 hb-text-light">
                            Add documents to be signed, require files to be uploaded, or set up other tasks for your new customers.
                        </v-col>
                        <v-col cols="3" class="ma-0 pa-0 text-right">
                            <a class="hb-link" @click="addTask()">+  Add Task</a>
                        </v-col>
                    </v-row>
                    <v-card elevation="1">
                        <v-data-table
                            :headers="headers"
                            :items="checklist"
                            disable-pagination
                            hide-default-footer
                            class="hb-data-table"
                        >
                            <template v-slot:item.name="{ item }">{{item.name}}</template>
                            <template v-slot:item.upload="{ item }">
                                <span v-show="!item.document_type_id && !item.document_id">No</span>
                                <span v-show="item.document_type_id || item.document_id "> Yes</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <hb-menu
                                    options
                                    align-right
                                >
                                    <v-list>
                                        <v-list-item @click="editItem(item)">
                                            <v-list-item-title>Edit</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="deleteChecklistItem(item)">
                                            <v-list-item-title>Delete</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </hb-menu>
                            </template>
                        </v-data-table>
                    </v-card>
                </div>

                <!-- <div class="table" v-show="checklist.length">
                    <div class="table-row">
                        <div class="table-head">
                            Name
                        </div>
                        <div class="table-head">
                            Requires upload
                        </div>
                        <div class="table-head actions">
                            &nbsp;
                        </div>
                    </div>

                    <div class="table-row" :key="checklist.id" v-for="checklist, index in checklist" @click="editItem(checklist)">

                        <div class="table-cell">
                            <strong>{{checklist.name}}</strong><br />
                            <span class="subdued">{{checklist.description}}</span>
                        </div>

                        <div class="table-cell">
                            <span v-show="!checklist.document_type_id && !checklist.document_id">No</span>
                            <span v-show="checklist.document_type_id || checklist.document_id "> Yes</span>
                        </div>


                        <div class="table-cell actions">
                            <span @click.stop="deleteChecklistItem(checklist)" class="icon delete"></span>
                        </div>

                    </div>
                </div> -->
            </div>

        </div>

        <!-- <modal v-if="addChecklistItem" size="lg" allow-click-away="false" @close="closeWindow">
            <h3 slot="header">Add Checklist Item</h3> -->
            <add-checklist-item v-if="addChecklistItem" :template_id="template_id" :selected="selected" size="lg" @close="closeWindow" @showSuccess="showSuccessMsg" @saved="saveChecklistItem">
            </add-checklist-item>
        <!-- </modal> -->
    </v-card>
</template>


<script type="text/babel">
    import Modal from '../../assets/Modal.vue';
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import draggable from 'vuedraggable'
    import AddChecklistItem from './AddChecklistItem.vue';

    import Promise from 'bluebird';

    import { EventBus } from '../../../EventBus.js';



    export default {
        name: "TemplateChecklists",
        data() {
            return {
                importLoading:false,
                list: [],
                checklist:[],
                showAdd: false,
                showEdit: false,
                showDelete: false,
                addChecklistItem:false,
                selected: {},
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Requires Upload", value: "upload" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
            }
        },
        components:{
            Modal,
            Status,
            draggable,
            Loader,
            AddChecklistItem
        },
        created(){
            this.fetchData();
        },
        destroyed(){},
        computed:{},
        methods:{


            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.addChecklistItem = false;
                this.selected = {};
            },

            fetchData(){

                api.get(this, api.TEMPLATES + this.template_id + '/checklist').then(r => {
                    this.checklist = r.checklist;
                });
            },

            addTask(){
                this.addChecklistItem = true;
            },


            editItem(c){
                this.selected = c;
                this.addChecklistItem = true;
            },
            deleteItem(c){
                this.selected = c;
                this.showDelete = true;
            },
            deleteConfirm(i){
                var item = i || this.selected;

                api.delete(this, api.PROPERTY_CHECKLISTS, item.id).then(r => {
                    this.showDelete = false;
                    this.fetchData();
                    this.selected = {};
                });

            },

            deleteChecklistItem(item){
                api.delete(this, api.TEMPLATES + this.template_id + '/checklist/',  item.id).then(r => {
                    this.fetchData();
                });
            },
            saveChecklistItem(){
                this.fetchData();
                this.selected = {};
                this.addChecklistItem = false;

            },
            saveNewOrder(){
                api.post(this, api.TEMPLATES + this.template_id + '/checklist-order', {checklist: this.checklist}).then(r => {
                    this.fetchData();
                });
            },
            showSuccessMsg(name, isEdited = false){
                this.successSet(this.$options.name, 'New Task "' + name + '" has been added to this lease template');
            }


        },
        props: ['template_id'],
        watch:{
            template_id(val){
                this.clearForm();
                if(val){
                    this.fetchData();
                }
            }
        }

    }
</script>

<style scoped>
    .grey-border{
        border-bottom : 1px solid #DFE3E8;;
    }
    .lease-heading{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
        .mrl--25px{
        margin-left: -25px;
        margin-right: -25px;
    }
    .key-label{
      background: #F4F6F8;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
    .add-role-btn{
        font-size: 15px;
        text-decoration: none;
    }
    .add-role-btn:focus{
        border: none;
    }
    .task-lease-table {
        background: #FFFFFF;
        box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
        border-radius: 4px;
    }
    .task-lease-table .table-head{
        background: #FFFFFF;
        /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
        border-bottom: 1px solid rgba(0, 0, 0, 0.22);
        color: #474F5A;
        font-size: 16px;
        font-weight: 500;
    }
    /* .task-lease-table .table-row:hover:not(:first-child){
        background: #F9FAFB;
    } */
    .task-lease-table .table-row {
        cursor: inherit;
    }
    .task-lease-table .table-row .table-cell{
        border-bottom: 1px solid #dce8ef;
    }
    .role-modal-header{
        font-size: 16px;
        color: #101318;
    }
    .no-border-all{
        border: none;
    }
    .light-text{
        color: #637381;
    }
    .key-heading{
        font-size: 15px;
        /* font-weight: 400; */
        color: #474F5A;
    }
    .bg-white{
        background-color: #FFFFFF;
    }
</style>
