<template>
    <hb-modal v-model="dialog" show-help-link size="large" title="Add Insurance Option" @close="$emit('close')">
        <template v-slot:subheader>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            Add the insurance options you want to make available for this lease.
        </template>
        <template v-slot:content>
            <v-row class="ma-0 pa-0">
                <v-col md="4" class="key-label pl-6 pt-5">
                    <div class="pt-0 font-15px">Insurance Option</div>
                </v-col>
                <v-col md="8" class="pa-3 pl-5 pr-7">
                    <div class="form-input-container">

                        <v-autocomplete
                            v-validate="'required'"
                            :items="insurance_products"
                            v-model="insurance"
                            name="insurance_id"
                            data-vv-name="insurance_id"
                            id="insurance_id"
                            data-vv-as="insurance"
                            solo 
                            flat                                     
                            item-text="name" 
                            item-value="id"
                            return-object
                            hide-details 
                            single-line
                            dense
                            label="Choose Insurance Option">
                            <template v-slot:item="data">
                                <div class="col pa-0 pb-3" style="max-width : 450px">
                                    <div>
                                        <em>Vendor: {{data.item.vendor}}</em>
                                    </div>
                                    <div>
                                        <strong>{{data.item.name}}</strong>
                                    </div>
                                    <div>
                                        {{data.item.description}}
                                    </div>
                                </div>
                            </template>
                        </v-autocomplete>
                        <span v-show="hasSubmitted" class="status-block error-block field-error">Please select an option you wish to add.</span>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="saveInsurance">Save</hb-btn>
            <span v-show="isLoading($options.name)">
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </template>
    </hb-modal>
</template>

<script>

    import Status from "../../includes/Messages.vue";
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';

    export default {
        name: "AddInsuranceDIalog",
        props : ['template_id', 'value'],
        components : {
            Status, Loader
        },
        data() {
            return {
                insurance: {
                    id: '',
                    name: '',
                },
                insurance_products: [],
                hasSubmitted: false,
                isOpened : true
            }
        },
        created() {
            this.searchInsurance();
        },
        computed: {
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
        },
        methods: {
            searchInsurance(){
                
                var params = {
                    search: ''
                };

                api.get(this, api.INSURANCE_SEARCH, params).then(r => {
                    this.insurance_products = r.insurance;
                });
            },
            saveInsurance(){
                var _this = this;
                if(_this.insurance.length === undefined && !_this.insurance.id){
                    _this.hasSubmitted = true;
                } else {
                    _this.hasSubmitted = false;
                }

                this.$validator.validateAll().then(function(status){

                    if(!status) throw "Validation Error";
                    _this.startLoading(_this.$options.name);

                    var service = {
                        template_id: _this.template_id,
                        product_id: _this.insurance.product_id,
                        qty: 1,
                        prorate: _this.insurance.prorate,
                        prorate_out: _this.insurance.prorate_out,
                        recurring: 1,
                        optional: 1,
                        price: 0
                    };

                    api.post(_this, api.TEMPLATES + _this.template_id + '/services/insurance', service).then(r=>{
                        _this.stopLoading(_this.$options.name);
                        _this.$emit('fetch');
                        _this.closeDialog();
                        _this.$emit('showSuccess', _this.insurance.name);
                    })

                }).catch(function(err){
                    _this.errorSet(_this.$options.name, "You have errors on your form.  Please fix them before continuing");
                });
            },
            closeDialog(){
                this.isOpened = false;
                setTimeout(() => {
                    this.$emit('close');
                }, 220);
            }
        },
    }
</script>

<style scoped>
    .grey-border{
        border-bottom : 1px solid #DFE3E8;;
    }
    .lease-heading{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
        .mrl--25px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .key-label{
      background: #FAF9FB;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
</style>
