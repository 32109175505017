<template>
    <v-card elevation="1">

        <div class="slide-out-section pa-0">
            
            <div class="form-section bg-white pa-0">
                <v-col class="ma-0 pt-3 pb-4 pl-0 pr-0">
                    <h2 class="font-weight-medium pl-5 pb-3">Products</h2>
                    <v-divider></v-divider>
                </v-col>

                <div class="px-6 pb-6">
                    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                    <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
                    <v-row class="mt-1 mb-6 px-4">
                        <v-col cols="9" class="ma-0 pa-0 hb-text-light">
                            You can add or manage products that are tied to this lease here.
                        </v-col>
                        <v-col cols="3" class="ma-0 pa-0 text-right">
                            <a class="hb-link" @click="addService('lease')">+ Add Product</a>
                        </v-col>
                    </v-row>
                    <v-card elevation="1">
                        <v-data-table
                            :headers="headers"
                            :items="services"
                            disable-pagination
                            hide-default-footer
                            class="hb-data-table"
                        >
                            <template v-slot:item.name="{ item }">{{item.Product.name}}</template>
                            <template v-slot:item.price="{ item }">{{item.price | formatMoney}}</template>
                            <template v-slot:item.recurring="{ item }">{{item.recurring ? 'Yes': 'No' }}</template>
                            <template v-slot:item.prorate="{ item }">{{ item.prorate ? 'Yes': 'No' }} / {{ item.prorate_out ? 'Yes': 'No' }}</template>
                            <template v-slot:item.actions="{ item }">
                                <hb-menu
                                    options
                                    align-right
                                >
                                    <v-list>
                                        <v-list-item @click="removeService(item)">
                                            <v-list-item-title>Delete</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </hb-menu>
                            </template>
                        </v-data-table>
                    </v-card>
                </div>

            </div>

        </div>

        <add-template-service
            v-model="showAddService"
            v-if="showAddService"
            :type="type"
            :template_id="template_id"
            :selected="service"
            @showSuccess="showSuccessMsg"
            @saved="saved">
        </add-template-service>

    </v-card>
</template>


<script type="text/babel">
    import Dropdown from "../../assets/Dropdown.vue";
    import Status from "../../includes/Messages.vue";
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import Modal from '../../assets/Modal.vue';
    import AddTemplateService from './AddTemplateService.vue';
    import { EventBus } from '../../../EventBus.js';
    export default{
        name: "LeaseTemplateProducts",
        data(){
            return {
                type: 'lease',
                service:{
                    id: null,
                    service_type: null,
                    template_id: null,
                    optional: false,
                    Product: {}
                },
                showAddService:false,
                services: [],
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Price", value: "price" },
                    { text: "Recurring", value: "recurring" },
                    { text: "Prorate in/out", value: "prorate" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
            }
        },
        components:{
            Dropdown,
            Status,
            Loader,
            Modal,
            AddTemplateService
        },
        created(){
            this.fetchData();
            EventBus.$on('undo_114', this.fetchData);
        },
        destroyed(){
            EventBus.$off('undo_114', this.fetchData);
        },
        methods:{
            addService(service_type){
                this.showAddService = true;
                this.service = {
                    id: null,
                    service_type: null,
                    optional: false,
                    template_id: null,
                    prorate: true,
                    prorate_out: true,
                    Product: {}
                }
                this.service.service_type = service_type;
                this.service.template_id = this.template_id;
            },
            editService(service){
                this.showAddService = true;
                this.service = JSON.parse(JSON.stringify(service));
            },
            fetchData(){
                api.get(this, api.TEMPLATES + this.template_id + '/services/' + this.type ).then(r => {
                    this.services = r.services;

                });
            },
            saved(){
                this.showAddService = false;
                this.fetchData();
            },
            removeService(service){
                api.delete(this, api.TEMPLATES + this.template_id + '/services/', service.id).then(r => {
                    this.fetchData();
                });
            },
            showSuccessMsg(name, isEdited = false){
                let msg = isEdited ? 'Product has been updated' : 'Product "' + name + '" has been added to this lease template'
                this.successSet(this.$options.name, msg);
            }
        },
        props: ['template_id'],
        watch:{
            template_id(val){
                this.clearForm();
                if(val){
                    this.fetchData();
                }
            }
        }
    }
</script>

<style scoped>
    .grey-border{
        border-bottom : 1px solid #DFE3E8;;
    }
    .lease-heading{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
        .mrl--25px{
        margin-left: -25px;
        margin-right: -25px;
    }
    .key-label{
      background: #F4F6F8;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
    .add-role-btn{
        font-size: 15px;
        text-decoration: none;
    }
    .add-role-btn:focus{
        border: none;
    }
    .products-lease-table {
        background: #FFFFFF;
        box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
        border-radius: 4px;
    }
    .products-lease-table .table-head{
        background: #FFFFFF;
        /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
        border-bottom: 1px solid rgba(0, 0, 0, 0.22);
        color: #474F5A;
        font-size: 16px;
        font-weight: 500;
    }
    .products-lease-table .table-row .delete-cell{
        visibility: hidden;
    }
    .products-lease-table .table-row:hover .delete-cell{
        visibility: visible;
    }
    .products-lease-table .table-row {
        cursor: inherit;
    }
    .products-lease-table .table-row .table-cell{
        border-bottom: 1px solid #dce8ef;
    }
    .role-modal-header{
        font-size: 16px;
        color: #101318;
    }
    .no-border-all{
        border: none;
    }
    .light-text{
        color: #637381;
    }
    .key-heading{
        font-size: 15px;
        color: #474F5A;
    }
    .bg-white{
        background-color: #FFFFFF;
    }
</style>
